import React from 'react';
import PropTypes from 'prop-types';

const PreLoader = ({ showLoader }) => {
  if (!showLoader) return null;

  return (
    <div className="d-flex align-items-center justify-content-center" style={{height: "100vh"}}>
      <div className="spinner-border m-3" role="status" aria-hidden="true"></div>
      <strong>Loading...</strong>
    </div>
  );
};

PreLoader.propTypes = {
  showLoader: PropTypes.bool.isRequired,
};

export default PreLoader;