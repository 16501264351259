import React, { useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import Select from "react-select";
import PreLoader from "../../PreLoader";
import { getApiUrl } from "../../../utils/AuthUtils";
import {
  getLocalStorageJSONData,
  setLocalStorageJSONData,
} from "../../../utils/Misc";
import Alert from "../../../components/Alert";
import { Button } from "react-bootstrap";
import ButtonLoader from "../../ButtonLoader";
import SuccessAlert from "../../SuccessAlert";
import SubmodulePermissions from "../Submodules/SubmodulePermissions";

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required("Title is required"),
  slug: Yup.string().trim().required("Slug is required"),
  status: Yup.object().required("Please select status"),
});

const RoleForm = (props) => {
  const formType = props.type;
  const currentUserRole = localStorage.getItem("role");

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [isDataSet, setIsDataSet] = useState(false);
  const [showFormModal, setShowFormModal] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState("");

  const [roleId, setRoleId] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);
  const [modulesList, setModulesList] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [selectedAccess, setSelectedAccess] = useState({});
  const [visibility, setVisibility] = useState("company");

  const [formData, setFormData] = useState({
    title: "",
    slug: "",
    description: "",
    status: null,
    is_predefined: "0",
    visibility: visibility,
    permissions: selectedPermissions,
    access: selectedAccess,
  });

  const {
    reset,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formData,
  });

  const fetchRoleMasterData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${getApiUrl()}/api/masters/roles-masters`
      );
      if (response) {
        setLocalStorageJSONData("status", response.data.data.status);
        setLocalStorageJSONData("permissions", response.data.data.permissions);
        setLocalStorageJSONData("modules", response.data.data.modules);
      }
      setStatusOptions(getLocalStorageJSONData("status"));
      setPermissionsList(getLocalStorageJSONData("permissions"));
      setModulesList(getLocalStorageJSONData("modules"));
      reset(formData);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    const fetchDataAndSetState = async () => {
      await fetchRoleMasterData(); // Fetch and set data in localStorage
      setIsDataSet(true); // Set state to indicate data has been set
    };
    fetchDataAndSetState();
  }, []);

  const fetchRoleData = async (roleId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${getApiUrl()}/api/masters/roles/${roleId}`
      );
      setSelectedPermissions(JSON.parse(response.data.data.permissions));
      setSelectedAccess(JSON.parse(response.data.data.access));
      const editFormData = {
        ...formData,
        ...response.data.data,
        status: statusOptions.filter(
          (status) => status.value === response.data.data.status
        )[0],
        is_predefined: response.data.data.is_predefined.toString(),
        permissions: JSON.parse(response.data.data.permissions),
        access: JSON.parse(response.data.data.access),
      };
      setVisibility(response.data.data.visibility);
      setFormData(editFormData);
      reset(editFormData);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isDataSet && props.id) {
      setRoleId(props.id);
      fetchRoleData(props.id); // Call fetchRoleData if data is set
    }
  }, [isDataSet, props.id]);

  const handlePermissionsChange = (permissions) => {
    setValue("permissions", permissions, { shouldDirty: true });
    setSelectedPermissions(permissions);
  };
  const handleAccessChange = (access) => {
    setValue("access", access, { shouldDirty: true });
    setSelectedAccess(access);
  };

  const onSubmitRole = async (data) => {
    let differFormFields = {};
    if (formType === "edit") {
      differFormFields = {
        id: roleId,
      };
    }
    const finalFormData = {
      title: data.title.trim(),
      slug: data.slug.trim(),
      description: data.description && data.description.trim(),
      status: data.status.value,
      is_predefined: data.is_predefined,
      visibility: visibility,
      permissions: data.permissions,
      access: data.access,
      ...differFormFields,
    };
    setSubmitting(true);
    let newFormData = finalFormData;
    const url =
      formType === "add"
        ? `${getApiUrl()}/api/masters/roles/create`
        : `${getApiUrl()}/api/masters/roles/update`;

    try {
      if (formType === "add") {
        await axios.post(url, newFormData);
        // Reset form after successful submission
        reset(formData);
      } else {
        const response = await axios.put(url, newFormData);
        const updated_row_count = response.data.data[0].updated_row_count;
        if (updated_row_count == 1) {
          reset(data);
        }
      }
      setSubmitting(false);
      setShowErrors(false);
      setShowFormModal(false);
      props.handleUpdateList();
      setTimeout(() => {
        props.closeModal();
      }, 3000);
    } catch (error) {
      if (error.response && error.response.status > 300) {
        const errorData = error.response.data.message;
        const errorField = error.response.data.field;
        setShowErrors(false);
        if (errorField) {
          setError(
            errorField,
            {
              type: "focus",
              message: errorData,
            },
            { shouldFocus: true }
          );
        } else {
          setErrorsMessage(errorData);
          setShowErrors(true);
        }
        setSubmitting(false);
      } else {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      {/* Role Form */}

      {showFormModal && (
        <div
          className="modal custom-modal fade modal-padding show"
          id="role_modal"
          role="dialog"
        >
          <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header header-border justify-content-between p-0">
                <h5 className="modal-title">{props.title}</h5>
                <button
                  type="button"
                  id="close-modal-role"
                  className="btn-close position-static"
                  onClick={() => {
                    props.closeModal();
                  }}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body p-0">
                <PreLoader showLoader={loading} />
                {!loading && (
                  <>
                    <div className="add-details-wizard">
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div
                            className="alert alert-secondary alert-dismissible fade show custom-alert-icon shadow-sm d-flex align-items-center"
                            role="alert"
                          >
                            <i className="feather-check-circle flex-shrink-0 me-2" />{" "}
                            Fields marked with{" "}
                            <span className="text-danger">
                              {" "}
                              &nbsp; * &nbsp;
                            </span>{" "}
                            are mandatory.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="add-info-fieldset">
                      <form
                        onSubmit={handleSubmit(onSubmitRole)}
                        id="role_form"
                      >
                        <div className="contact-input-set">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Title <span className="text-danger">*</span>
                                </label>
                                <Controller
                                  name="title"
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      className={`form-control ${
                                        errors?.title ? "error-input" : ""
                                      }`}
                                      id="role_title"
                                      type="text"
                                      maxLength={255}
                                      onChange={field.onChange}
                                      value={field.value}
                                      autoComplete="true"
                                    />
                                  )}
                                />
                                <span className="text-danger form-text">
                                  {errors.title?.message}{" "}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Slug <span className="text-danger">*</span>
                                </label>
                                <Controller
                                  name="slug"
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      className={`form-control ${
                                        errors?.slug ? "error-input" : ""
                                      }`}
                                      type="text"
                                      id="role_slug"
                                      maxLength={255}
                                      onChange={field.onChange}
                                      value={field.value}
                                      autoComplete="true"
                                      readOnly={formType === "edit"}
                                    />
                                  )}
                                />
                                <span className="text-danger form-text">
                                  {errors.slug?.message}{" "}
                                </span>
                              </div>
                            </div>
                            {currentUserRole === "super-admin" && (
                              <>
                                <div className="col-md-6">
                                  <label className="col-form-label">
                                    Is Predefined Role?
                                  </label>
                                  <div className="">
                                    <Controller
                                      name="is_predefined"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <div className="form-check form-check-inline">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              value="1"
                                              id="is_predefined_yes"
                                              checked={field.value === "1"}
                                              onChange={field.onChange}
                                            />
                                            <label>Yes</label>
                                          </div>
                                          <div className="form-check form-check-inline">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              value="0"
                                              id="is_predefined_no"
                                              checked={field.value === "0"}
                                              onChange={field.onChange}
                                            />
                                            <label>No</label>
                                          </div>
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <label className="col-form-label">
                                    Visibility?
                                  </label>
                                  <div className="">
                                    <Controller
                                      name="visibility"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <div className="form-check form-check-inline">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              value="super-admin"
                                              id="visibility_super_admin"
                                              checked={
                                                field.value === "super-admin"
                                              }
                                              onChange={(e) => {
                                                setValue(
                                                  "visibility",
                                                  e.target.value,
                                                  { shouldDirty: true }
                                                );
                                                setVisibility(e.target.value);
                                              }}
                                            />
                                            <label>Super Admin</label>
                                          </div>
                                          <div className="form-check form-check-inline">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              value="company"
                                              id="visibility_company"
                                              checked={
                                                field.value === "company"
                                              }
                                              onChange={(e) => {
                                                setValue(
                                                  "visibility",
                                                  e.target.value,
                                                  { shouldDirty: true }
                                                );
                                                setVisibility(e.target.value);
                                              }}
                                            />
                                            <label>Entity</label>
                                          </div>
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Status <span className="text-danger">*</span>
                                </label>
                                <Controller
                                  name="status"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      className={`${
                                        errors?.status ? "error-input" : ""
                                      }`}
                                      {...field}
                                      id="role_status"
                                      onChange={field.onChange}
                                      value={field.value}
                                      options={statusOptions}
                                      isClearable={true}
                                    />
                                  )}
                                />
                                <span className="text-danger form-text">
                                  {errors.status?.message}{" "}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Description
                                </label>
                                <Controller
                                  name="description"
                                  control={control}
                                  render={({ field }) => (
                                    <textarea
                                      className="form-control"
                                      id="role_description"
                                      rows={5}
                                      onChange={field.onChange}
                                      value={field.value}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <SubmodulePermissions
                                modules={modulesList}
                                permissions={permissionsList}
                                visibility={visibility}
                                onPermissionsChange={handlePermissionsChange}
                                selectedPermissions={selectedPermissions}
                                onAccessChange={handleAccessChange}
                                selectedAccess={selectedAccess}
                              />
                            </div>
                            {showErrors && (
                              <>
                                <div className="col-sm-12 mb-3">
                                  <Alert
                                    message={errorsMessage}
                                    type="danger"
                                    close={true}
                                  />
                                </div>
                              </>
                            )}
                            <div className="col-lg-12 text-end form-wizard-button">
                              {formType === "add" && (
                                <button
                                  className="button btn-lights reset-btn"
                                  type="reset"
                                  id="role_form_reset"
                                  onClick={() => {
                                    setFormData({ ...formData });
                                    reset(formData);
                                    setShowErrors(false);
                                  }}
                                >
                                  Reset
                                </button>
                              )}

                              <Button
                                variant="success"
                                type="submit"
                                className="mx-1"
                                id="role_form_submit"
                                disabled={!isDirty || submitting}
                              >
                                {!submitting ? (
                                  formType === "edit" ? (
                                    "Update"
                                  ) : (
                                    "Submit"
                                  )
                                ) : (
                                  <ButtonLoader text="Submitting..." />
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Role Form */}
      {/* Success Role */}
      {!showFormModal && (
        <div
          className="modal custom-modal fade show"
          id="success_msg_role"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <SuccessAlert
              itemName="Role"
              action={formType === "edit" ? "update" : "create"}
              onClick={() => props.closeModal()}
            />
          </div>
        </div>
      )}
      {/* /Success Role */}
    </>
  );
};

export default RoleForm;
