import axios from 'axios';

const setupAxiosInterceptors = () => {

  // Request Interceptor (optional if you want to attach token to headers)
  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token"); // Adjust this based on how you store tokens
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response Interceptor
  axios.interceptors.response.use(
    (response) => response, // If the response is successful, just return it
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        // Redirect to login page if a 401 error is encountered
        // navigate('/login');
        window.location.href = '/login';

      }
      if (error.response && error.response.status === 403) {
        window.location.href = '/403';

      }
      return Promise.reject(error);
    }
  );
};

export default setupAxiosInterceptors;
