import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../../utils/AuthUtils';

const ProtectedRoute = ({ children }) => {
  const location = useLocation(); // To save the current path

  if (!isAuthenticated()) {
    // If not logged in, redirect to login with the original location saved
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // If logged in, allow access to the route
  return children;
};

export default ProtectedRoute;