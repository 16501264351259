import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import store from "../../store";
import { validateDomain } from "../../utils/AuthUtils"; // Import the authentication check function
import { Provider } from "react-redux";
import Login from "../../views/pages/Authentication/Login";
import Logout from "../../views/pages/Authentication/Logout";
import Register from "../../views/pages/Authentication/Register";
import Otp from "../../views/pages/Authentication/Otp";
import Error404 from "../../views/pages/Error/Error404";
import Error500 from "../../views/pages/Error/Error500";
import ForgotPassword from "../../views/pages/Authentication/ForgotPassword";
import ComingSoon from "../../views/pages/Pages/ComingSoon";
import UnderManitenance from "../../views/pages/Pages/UnderManitenance";
import CandidateLogin from "../../views/pages/Authentication/CandidateLogin";
import ProtectedRoute from "./ProtectedRoute";
import PreLoader from "../../components/PreLoader";
import Error403 from "../../views/pages/Error/Error403";

const AppContainer = React.lazy(() => import("../Appcontainer"));

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRouter = () => {
  const [isValidDomain, setIsValidDomain] = useState(true);
  const [isDataSet, setIsDataSet] = useState(false);

  const checkDomain = async () => {
    try {
      const isValid = await validateDomain(); // Await the result of validateDomain
      if (isValid) {
        setIsValidDomain(true);
        // Proceed with the application
      } else {
        setIsValidDomain(false);
        // Handle invalid domain case (e.g., redirect to error page)
      }
    } catch (error) {
      console.error("Error validating domain:", error);
      setIsValidDomain(false); // Handle error case by marking the domain as invalid
    }
  };
  useEffect(() => {
    // Define an async function to handle domain validation

    const fetchDataAndSetState = async () => {
      localStorage.setItem("colorschema", "blue");
      localStorage.setItem("layout", "vertical");
      localStorage.setItem("layoutwidth", "fluid");
      localStorage.setItem("layoutpos", "fixed");
      localStorage.setItem("topbartheme", "light");
      localStorage.setItem("layoutSized", "lg");
      localStorage.setItem("layoutStyling", "default");
      localStorage.setItem("layoutSidebarStyle", "light");
      await checkDomain(); // Fetch and set data in localStorage
      setIsDataSet(true); // Set state to indicate data has been set
    };
    fetchDataAndSetState();
  }, []);

  return (
    <div>
      <Provider store={store}>
        <BrowserRouter basename="">
          <Suspense fallback={<PreLoader showLoader={true} />}>
            <ScrollToTop />
            {isDataSet &&
              (!isValidDomain ? (
                // Render the 404 error page if the domain is not valid
                <Routes>
                  <Route path="/*" element={<Error404 />} />
                </Routes>
              ) : (
                // Render the rest of the routes if the domain is valid
                <Routes>
                  {/* Public Routes */}
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/candidate-login/:hash"
                    element={<CandidateLogin />}
                  />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/otp" element={<Otp />} />
                  <Route path="/error-404" element={<Error404 />} />
                  <Route path="/error-500" element={<Error500 />} />
                  <Route path="/coming-soon" element={<ComingSoon />} />
                  <Route
                    path="/under-maintenance"
                    element={<UnderManitenance />}
                  />
                  <Route path="/logout" element={<Logout />} />

                  {/* Protected Routes */}
                  <Route
                    path="/403"
                    element={
                      <ProtectedRoute>
                        <Error403 />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/*"
                    element={
                      <ProtectedRoute>
                        <AppContainer />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              ))}
          </Suspense>
        </BrowserRouter>
      </Provider>
    </div>
  );
};

export default AppRouter;
