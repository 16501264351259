import React from "react";
import { Link } from "react-router-dom";

const Error403 = () => {
  return (
    <>
      {/* Main Wrapper */}
      <div className="error-page">
        <div className="main-wrapper">
          <div className="error-box">
            <h1>403</h1>
            <h3>
              <i className="fa-solid fa-triangle-exclamation" /> 
            </h3>
            <p>You do not have the necessary permissions to access the requested page.</p>
            <Link to="/dashboard" className="btn btn-custom">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error403;
