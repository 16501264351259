import React from 'react';

const ButtonLoader = ({ text, className }) => {
  const buttonText = text || 'Loading...';

  const btnClass = `spinner-border spinner-border-sm me-1 ${className}`;

  return (
    <>
      <span className={btnClass} />{" "}
      {buttonText}
    </>
  );
};

export default ButtonLoader;
