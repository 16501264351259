import React, { useEffect, useState } from "react";

const SubmodulePermissions = (props) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isModuleChecked, setIsModuleChecked] = useState({});
  const [isSubmoduleChecked, setIsSubmoduleChecked] = useState({});
  const [isSubmoduleAccessChecked, setIsSubmoduleAccessChecked] = useState({});
  const [isSubmodulePermissionsChecked, setIsSubmodulePermissionsChecked] =
    useState({});

  const [modulesList, setModulesList] = useState([]);
  // New flag to check if initialization is complete
  const [isInitialized, setIsInitialized] = useState(false);
  const permissionsList = props.permissions || [];
  // console.log(props.visibility);
    
  useEffect(() => {
    setModulesList((props.modules &&
      props.modules
        .filter(
          (module) =>
            module.visibility === props.visibility ||
            module.visibility === "both"
        )
        .map((module) => {
          // Assuming each module has a submodules array
          const filteredSubmodules = module.submodules
            ? module.submodules.filter(
                (submodule) =>
                  submodule.visibility === props.visibility ||
                  submodule.visibility === "both"
              )
            : [];

          // Return the module with filtered submodules
          return {
            ...module,
            submodules: filteredSubmodules,
          };
        })) ||
    []);
  }, [props.visibility]);
  
  useEffect(() => {
    initPermissions();
    // Set initialized state to true after permissions are set
    setIsInitialized(true);
  }, [modulesList, permissionsList]);

  useEffect(() => {
    if (isInitialized && props.onPermissionsChange) {
      const selectedPermissions = getSelectedPermissions();
      props.onPermissionsChange(selectedPermissions);
    }
  }, [isSubmodulePermissionsChecked, isInitialized]); // Trigger when permissions change

  useEffect(() => {
    if (isInitialized && props.onAccessChange) {
      props.onAccessChange(isSubmoduleAccessChecked);
    }
  }, [isSubmoduleAccessChecked, isInitialized]); // Trigger when Access change

  useEffect(() => {
    // Set selected permissions from props when they change
    if (modulesList.length > 0 && props.selectedPermissions) {
      setSelectedPermissions(props.selectedPermissions);
    }
    if (modulesList.length > 0 && props.selectedAccess) {
      setIsSubmoduleAccessChecked((prev) => {
        const newState = { ...prev, ...props.selectedAccess };
        return newState;
      });
    }
  }, [modulesList]);

  const getSelectedPermissions = () => {
    const selectedPermissions = {};

    Object.keys(isSubmodulePermissionsChecked).forEach((submoduleId) => {
      const permissions = Object.keys(
        isSubmodulePermissionsChecked[submoduleId]
      ).filter(
        (permissionId) =>
          isSubmodulePermissionsChecked[submoduleId][permissionId]
      );

      if (permissions.length > 0) {
        selectedPermissions[submoduleId] = permissions;
      }
    });

    return selectedPermissions;
  };

  const setSelectedPermissions = (selectedPermissions) => {
    const updatedSubmodulePermissionsChecked = {};

    Object.keys(selectedPermissions).forEach((submoduleId) => {
      updatedSubmodulePermissionsChecked[submoduleId] = {};
      selectedPermissions[submoduleId].forEach((permissionId) => {
        updatedSubmodulePermissionsChecked[submoduleId][permissionId] = true;
      });
    });

    setIsSubmodulePermissionsChecked((prev) => {
      const newState = { ...prev, ...updatedSubmodulePermissionsChecked };
      checkAllSubmodulesPermissionsChecked(updatedSubmodulePermissionsChecked);
      return newState;
    });

    // Check if all permissions for each submodule are checked
  };

  const initPermissions = () => {
    const moduleList = {};
    const submodulesList = {};
    const submodulesAccessList = {};
    const submodulePermissionsList = {};

    modulesList.forEach((module) => {
      if (module.submodules.length > 0) {
        module.submodules.forEach((submodule) => {
          const permissionData = JSON.parse(submodule.permissions || "[]");

          // Initialize submodule state
          submodulesList[submodule.id] = false;
          submodulesAccessList[submodule.id] = "self";

          // Initialize permissions for submodule
          if (permissionData.length) {
            permissionData.forEach((submodulePermission) => {
              const validPermission = permissionsList.find(
                (permission) => permission.id === submodulePermission.value
              );

              if (validPermission) {
                if (!submodulePermissionsList[submodule.id]) {
                  submodulePermissionsList[submodule.id] = {};
                }
                submodulePermissionsList[submodule.id][
                  validPermission.id
                ] = false;
              }
            });
          }
        });
        moduleList[module.id] = false; // Track module state
      }
    });

    setIsModuleChecked(moduleList);
    setIsSubmoduleChecked(submodulesList);
    setIsSubmoduleAccessChecked(submodulesAccessList);
    setIsSubmodulePermissionsChecked(submodulePermissionsList);
  };

  const handleCheckAll = (e) => {
    setIsCheckAll(e.target.checked);
    modulesList.forEach((module) => {
      updateModuleCheckbox(module.id, e.target.checked);
    });
  };

  const handleModuleChecked = (e, id) => {
    updateModuleCheckbox(id, e.target.checked);
  };

  const updateModuleCheckbox = (module_id, value) => {
    setIsModuleChecked((prev) => {
      const newState = { ...prev, [module_id]: value };
      checkIfAllModulesChecked(newState);
      return newState;
    });

    const moduleData = modulesList.find((module) => module.id === module_id);

    if (moduleData && moduleData.submodules.length > 0) {
      moduleData.submodules.forEach((submodule) => {
        updateSubmoduleCheckbox(submodule.id, value);
      });
    }
  };

  const checkIfAllModulesChecked = (newState) => {
    const allModulesChecked = modulesList.every((module) => {
      // Check if the module exists in newState, treat as true if it doesn't exist
      return newState[module.id] !== undefined
        ? newState[module.id] === true
        : true;
    });
    setIsCheckAll(allModulesChecked);
  };

  const handleSubmoduleChecked = (e, submodule_id) => {
    updateSubmoduleCheckbox(submodule_id, e.target.checked);
  };

  const handleSubmoduleAccessChecked = (value, submodule_id) => {
    setIsSubmoduleAccessChecked((prev) => {
      const newState = { ...prev, [submodule_id]: value };
      return newState;
    });
  };

  const updateSubmoduleCheckbox = (submodule_id, value) => {
    setIsSubmoduleChecked((prev) => {
      const newState = { ...prev, [submodule_id]: value };
      checkModuleIfAllSubmodulesChecked(submodule_id, newState);
      return newState;
    });

    setIsSubmodulePermissionsChecked((prev) => {
      const newState = { ...prev };
      const submodule = modulesList
        .flatMap((module) => module.submodules)
        .find((sub) => sub.id === submodule_id);

      if (submodule) {
        const permissionData = JSON.parse(submodule.permissions || "[]");

        if (permissionData.length) {
          permissionData.forEach((submodulePermission) => {
            const validPermission = permissionsList.find(
              (permission) => permission.id === submodulePermission.value
            );

            if (validPermission) {
              newState[submodule_id] = {
                ...newState[submodule_id],
                [validPermission.id]: value,
              };
            }
          });
        }
      }
      return newState;
    });
  };

  const checkModuleIfAllSubmodulesChecked = (submodule_id, newState) => {
    const submodule = modulesList
      .flatMap((module) => module.submodules)
      .find((sub) => sub.id === submodule_id);

    if (submodule) {
      const parentModule = modulesList.find((module) =>
        module.submodules.some((sub) => sub.id === submodule_id)
      );

      if (parentModule) {
        const allSubmodulesChecked = parentModule.submodules.every(
          (sub) => newState[sub.id] === true
        );

        setIsModuleChecked((prev) => {
          const newState = { ...prev, [parentModule.id]: allSubmodulesChecked };
          checkIfAllModulesChecked(newState);
          return newState;
        });
      }
    }
  };

  const handleSubmodulePermissionChecked = (e, submodule_id, permission_id) => {
    setIsSubmodulePermissionsChecked((prev) => {
      const newState = {
        ...prev,
        [submodule_id]: {
          ...prev[submodule_id],
          [permission_id]: e.target.checked,
        },
      };
      checkAllSubmodulesPermissionsChecked(newState);
      return newState;
    });
  };

  const checkAllSubmodulesPermissionsChecked = (newState) => {
    const submodules = modulesList.flatMap((module) => module.submodules);

    submodules.forEach((submodule) => {
      const permissionData = JSON.parse(submodule.permissions || "[]");
      const allChecked =
        permissionData.length === 0 ||
        permissionData.every((permission) => {
          const validPermission = permissionsList.find(
            (p) => p.id === permission.value
          );
          return (
            !validPermission ||
            newState[submodule.id]?.[validPermission.id] === true
          );
        });

      setIsSubmoduleChecked((prev) => {
        const newState = { ...prev, [submodule.id]: allChecked };
        checkModuleIfAllSubmodulesChecked(submodule.id, newState);
        return newState;
      });
    });
  };

  return (
    <>
      <div className="table-responsive m-t-15">
        <table className="table table-striped custom-table">
          <thead>
            <tr key="chk_all">
              <th>
                <div className="form-check-inline">
                  <input
                    type="checkbox"
                    id="check_all"
                    className="form-check-input"
                    value="all"
                    onChange={(e) => handleCheckAll(e)}
                    checked={isCheckAll}
                  />
                  <label className="form-check-label" htmlFor={`check_all`}>
                    Check All
                  </label>
                </div>
              </th>
              <th>
                <span className="custom_title text-center">Permissions</span>
              </th>
              <th>
                <span className="custom_title text-center">Access</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {modulesList.map((module, key_m) => {
              return (
                module.submodules.length > 0 && (
                  <React.Fragment key={`mk_${key_m}_module`}>
                    <tr key={`mk_${key_m}`}>
                      <td colSpan={3}>
                        <div
                          className="form-check-inline"
                          key={`module_${module.id}`}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`module_${module.id}`}
                            value={`module_${module.id}`}
                            onChange={(e) => handleModuleChecked(e, module.id)}
                            checked={isModuleChecked[module.id] || false}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`module_${module.id}`}
                          >
                            {module.title}
                          </label>
                        </div>
                      </td>
                    </tr>
                    {module.submodules.length > 0 &&
                      module.submodules.map((submodule, key_s) => {
                        return (
                          <tr key={`smk_${key_m}_${key_s}`}>
                            <td>
                              <div className="submodule_title">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={`submodule_${submodule.id}`}
                                  value={`submodule_${submodule.id}`}
                                  onChange={(e) =>
                                    handleSubmoduleChecked(e, submodule.id)
                                  }
                                  checked={
                                    isSubmoduleChecked[submodule.id] || false
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`submodule_${submodule.id}`}
                                >
                                  {submodule.title}
                                </label>
                              </div>
                            </td>
                            <td>
                              {submodule.permissions &&
                                permissionsList.map((permission, key_p) => {
                                  const permissionData = JSON.parse(
                                    submodule.permissions || "[]"
                                  ).filter(
                                    (submodulePermission) =>
                                      permission.id ===
                                      submodulePermission.value
                                  );
                                  return (
                                    <div
                                      className="form-check-inline"
                                      key={`pmk_${submodule.id}_${key_p}`}
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        disabled={permissionData.length === 0}
                                        onChange={(e) =>
                                          handleSubmodulePermissionChecked(
                                            e,
                                            submodule.id,
                                            permission.id
                                          )
                                        }
                                        checked={
                                          (permissionData.length !== 0 && // Make sure permission data is not empty
                                            isSubmodulePermissionsChecked[
                                              submodule.id
                                            ] && // Ensure submodule exists
                                            isSubmodulePermissionsChecked[
                                              submodule.id
                                            ][permission.id]) || // Check the specific permission
                                          false
                                        }
                                        id={`submodule_permission_${submodule.id}_${permission.id}`}
                                        value={`submodule_permission_${submodule.id}_${permission.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`submodule_permission_${submodule.id}_${permission.id}`}
                                      >
                                        {permission.title}
                                      </label>
                                    </div>
                                  );
                                })}
                            </td>
                            <td>
                              <div className="form-check-inline">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id={`submodule_${submodule.id}_full`}
                                  value="full"
                                  onChange={() =>
                                    handleSubmoduleAccessChecked(
                                      "full",
                                      submodule.id
                                    )
                                  }
                                  checked={
                                    isSubmoduleAccessChecked[submodule.id] ===
                                      "full" || false
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`submodule_${submodule.id}_full`}
                                >
                                  Full
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id={`submodule_${submodule.id}_team`}
                                  value="team"
                                  onChange={() =>
                                    handleSubmoduleAccessChecked(
                                      "team",
                                      submodule.id
                                    )
                                  }
                                  checked={
                                    isSubmoduleAccessChecked[submodule.id] ===
                                      "team" || false
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`submodule_${submodule.id}_team`}
                                >
                                  Team
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id={`submodule_${submodule.id}_self`}
                                  value="self"
                                  onChange={() =>
                                    handleSubmoduleAccessChecked(
                                      "self",
                                      submodule.id
                                    )
                                  }
                                  checked={
                                    isSubmoduleAccessChecked[submodule.id] ===
                                      "self" || false
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`submodule_${submodule.id}_self`}
                                >
                                  Self
                                </label>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </React.Fragment>
                )
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SubmodulePermissions;
